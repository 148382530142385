<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Payment options</a></li>
        </ul>
      </div>
    </div>
    <section class="section--registration">
      <div class="container">
        <h2 class="page__title">Payment Option</h2>
        <p class="page__subtitle">
          Get started by just filling out one simple form
        </p>
        <div class="registration__content cont">

          <div class="slide-loading">
            <loading v-model:active="isLoading" :is-full-page="fullPage" background-color="#f7f7f7" color="#ff7200">
            </loading>
          </div>

          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="registration__info">

                <form @submit.prevent="validateJobID">
                  <div class="form-row">
                    <div class="col-12 form-group--block">
                      <label>Enter the job ID: <span>*</span></label>
                      <div class="input-group">
                        <input class="form-control" :class="{ 'is-invalid': $errors.has('job_id') }" type="text"
                          placeholder="xxxxxxxx" v-model="jobId" />
                        <button class="btn verfy-btn" type="submit" :disabled="isLoading || !jobId">
                          {{ isLoading ? 'Verifiying...' : 'Verify'}}
                        </button>
                        <div class="invalid-feedback" style="font-weight:bold" v-if="$errors.has('job_id')">
                          {{ $errors.first("job_id") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <template v-if="job && job.email && jobId">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-box">
                        <div class="title">
                          Sender Information <small>(You)</small>
                        </div>
                        <div class="content">
                          <div class="heading">{{ user.first_name || 'Unknown' }} {{ user.last_name || '-' }}</div>
                          <div>{{ user.email || '-' }}</div>
                          <div>{{ user.phone || '-' }}</div>
                        </div>
                      </div>
                    </div>

                     <div class="col-sm-4">
                      <div class="card-box">
                        <div class="title">
                          Receiver Information
                        </div>
                        <div class="content">
                          <div class="heading">{{ job.first_name || 'Unknown' }} {{ job.last_name }}</div>
                          <div>{{ job.email || '-' }}</div>
                          <div>{{ job.phone_no || '-' }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="card-box bg-theme">
                        <div class="title">
                          Payment Information
                        </div>
                        <div class="content">
                          <div class="amount">{{ job.format_cost }}</div>
                          <div class="pay-button">
                            <a href="" @click.prevent="onPay">Pay now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form :action="webxpayPayUrl" method="get" id="job-form">
                    <input type="hidden" name="jobId" :value="job.job_id">
                    <input type="hidden" name="sender_id" :value="user.id">
                  </form>

                </template>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="vender__content">
        <div class="row m-0 f">
          <div class="col-6 col-lg-4 vender__item">
            <div class="vender__item__content">
              <div class="vender__number">
                <img class="card-img" src="https://i.pinimg.com/originals/81/c2/44/81c244eb9665baef1305f47352da5fc9.png"
                  alt="card" />
              </div>
              <p>
                <label class="card-heading">Multiple Payment Options</label><br />Credit/Debit Cards, Bank Transfer &
                Cash Deposit
                available
              </p>
            </div>
          </div>
          <div class="col-6 col-lg-4 vender__item">
            <div class="vender__item__content">
              <div class="vender__number">
                <img class="card-img"
                  src="https://png.pngtree.com/png-vector/20190504/ourmid/pngtree-shield-icon-design-png-image_1017528.jpg"
                  alt="card" />
              </div>
              <p>
                <label class="card-heading">Secured Payments</label><br />Payments are secured by Webxpay online payment solution provider
              </p>
            </div>
          </div>
          <div class="col-6 col-lg-4 vender__item">
            <div class="vender__item__content">
              <div class="vender__number">
                <img class="card-img"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Noun_project_network_icon_1365244_cc.svg/981px-Noun_project_network_icon_1365244_cc.svg.png"
                  alt="card" />
              </div>
              <p>
                <label class="card-heading">Branch Network</label><br />
                Jaffna  <br>
                Kilinochchi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
  import {
    mapActions,
    mapGetters
  } from "vuex";
  import {
    WEBXPAY_PAY_URL
  } from "@/common/config";

  export default {
    data() {
      return {
        fullPage: false,
        jobId: null,
        isLoading: false,
        state: '',
        formData: {
          state: '',
          country: '',
          city: ''
        }
      }
    },

    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters("job", ["job"]),
      webxpayPayUrl() {
        return WEBXPAY_PAY_URL;
      }
    },

    methods: {
      ...mapActions("job", ["fetchByJobId"]),
      validateJobID() {
        this.isLoading = true;
        this.fetchByJobId(this.jobId).then(() => {
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        }).finally(()=>{
          this.isLoading = false;
        })
      },
      onPay(){
        document.getElementById('job-form').submit()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card-box {
    border: 1px solid #e3e3e3;
    margin-top: 2rem;
    padding: 1.5rem;
    box-shadow: 2px 2px 9px 0px #ddd;
    border-radius: 10px;
    &.bg-theme{
      background: #ff7200;
      color: #fff;
    }
    .title{
      font-weight: bold;
      font-size: 1.7rem;
      text-transform: uppercase;
    }
    .content {
      div{
        color:#919191;
      }
      .heading{
        color: #000;
      }
       .amount{
        color:#fff;
        font-size: 3rem;
        font-weight: bolder;
      }
      .pay-button{
        text-align: right;
        a{
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          border: 1px solid #fff;
          padding: 3px 20px;
          border-radius: 10px;
          font-size: 1.4rem;
        }
        a:hover{
          color: #ddd;
        }
      }
    }
   
    
  }

  .btn-dg {
    background-color: #646464;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 7px;
    margin: 20px 0 40px;
  }

  .btn {
    width: 80px;
  }

  .btn-dg:hover {
    color: black;
  }

  .verfy-btn {
    background-color: #ff7200;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0 2px 2px 0;
    width: 100px;
  }

  .vender__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 0 10px;
    padding: 30px;
    text-align: center;
  }

  .card-heading {
    font-weight: 800;
    font-size: 17px;
    color: rgb(51, 51, 51);
  }

  .card-img {
    height: 80px;
    width: 80px;
  }

  @media (max-width: 1024px) {
    .cont {
      margin-left: 0;
      margin-right: 0;
    }

    .f,
    .vender__content {
      visibility: hidden;
      display: none;
    }
  }

  .form-group--block textarea {
    min-height: auto;
  }
</style>